@import "themes/variables";

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.container {
  height: 100%;
  width: 100%;
  display: flex;
  position: fixed;
  font-family: var(--fontFamily);
  background: var(--themeColor);
  color: var(--color);
  @media (max-width: 599px) {
    flex-direction: column;
    justify-content: space-between;
  }

}

.innerContainer {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 94%;
}
