@import 'themes/variables';

.alert {
  font-family: var(--fontFamily) !important;
  align-items: center;
}

.linkColor {
  color: var(--white);
}
