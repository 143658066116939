@import 'themes/variables';

.main {
    display: flex;
    height: 8%;
    border-bottom: 1px solid var(--boxBorder);
    align-items: center;
    justify-content: space-between;
    padding: 1em;
    background-color: var(--background);
}

.text {
    display: flex;
    color: var(--builderPurpleA2);
    text-decoration: none;
}