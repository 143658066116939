@import 'themes/variables';

.sideBar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0.3em;
  z-index: 1;
  background: var(--background);
  border-right: 1px solid var(--borderColor);
}

.icons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.help {
  margin-bottom: 1em;
  border-bottom: 1px solid var(--borderColor);
  cursor: pointer;
}

.mobileContainer {
  flex-direction: row;
  border-bottom: 1px solid var(--borderColor);
  padding: 0.5em;
  display: none;
}

.builderIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1.5em;
}

.icon {
  padding: 1em 0em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.activeIcon {
  display: flex;
  width: 34px;
  height: 34px;
  border-radius: 6px;
  background: var(--activeBackground);
}
