/* Emoji-Mart CSS */
/* Gallery View Carousel CSS */
/* Custom Email CSS */
/* Date-Picker CSS */
/* Phone-Input CSS */
#lightTheme {
  em-emoji-picker {
    height: 50vh;
    max-height: 300px;
  }
  .carousel__slider-tray-wrap--horizontal {
    height: 100%;
  }
  .carousel__slider-tray--horizontal {
    height: 100%;
  }
  .slide___3-Nqo {
    height: 100%;
  }
  .react-datepicker {
    background: white;
    border: 1px solid #dfe0e6;
    font-family: 'Rubik', sans-serif;
  }
  .react-datepicker__header {
    background: white;
    border: 1px solid #dfe0e6;
    font-family: 'Rubik', sans-serif;
  }
  .react-datepicker-popper[data-placement^='bottom'] {
    .react-datepicker__triangle {
      &::before {
        border-bottom-color: #dfe0e6;
      }
      &::after {
        border-bottom-color: #dfe0e6;
      }
    }
  }
  .react-datepicker__day-name {
    color: black;
  }
  .react-datepicker__day {
    color: black;
    &:hover {
      background-color: #7c4dff;
    }
  }
  .react-datepicker__time-name {
    color: black;
  }
  .react-datepicker__current-month {
    color: black;
  }
  .react-datepicker-time__header {
    color: black;
  }
  .react-datepicker-year-header {
    color: black;
  }
  .react-datepicker__day--selected {
    background-color: #7c4dff;
    &:hover {
      background-color: #7c4dff;
    }
  }
  .react-datepicker__day--keyboard-selected {
    background-color: #7c4dff;
  }
  .react-datepicker__month-text--keyboard-selected {
    background-color: #7c4dff;
  }
  .react-datepicker__quarter-text--keyboard-selected {
    background-color: #7c4dff;
  }
  .react-datepicker__year-text--keyboard-selected {
    background-color: #7c4dff;
  }
  .react-datepicker__month-text {
    &:hover {
      background-color: #7c4dff;
    }
  }
  .react-datepicker__quarter-text {
    &:hover {
      background-color: #7c4dff;
    }
  }
  .react-datepicker__year-text {
    &:hover {
      background-color: #7c4dff;
    }
  }
  .react-datepicker__day--disabled {
    color: gray;
    background: none;
    &:hover {
      color: gray;
      background: none;
    }
  }
  .react-datepicker__month-text--disabled {
    color: black;
    background: none;
    &:hover {
      color: black;
      background: none;
    }
  }
  .react-datepicker__quarter-text--disabled {
    color: black;
    background: none;
    &:hover {
      color: black;
      background: none;
    }
  }
  .react-datepicker__year-text--disabled {
    color: black;
    background: none;
    &:hover {
      color: black;
      background: none;
    }
  }
  .react-datepicker__tab-loop {
    display: flex;
    position: absolute;
    margin-top: -9em;
  }
  .react-tel-input {
    .form-control {
      background-color: white;
      border: 2px solid #dfe0e6;
      color: black;
      font-family: 'Rubik', sans-serif;
    }
    .flag-dropdown {
      background-color: white;
      border: 2px solid #dfe0e6;
      color: black;
      font-family: 'Rubik', sans-serif;
      &:hover {
        background-color: white;
        color: black;
        font-family: 'Rubik', sans-serif;
      }
      &:focus {
        background-color: white;
        color: black;
        font-family: 'Rubik', sans-serif;
      }
    }
    .country-list {
      background-color: white;
      border: 2px solid #dfe0e6;
      color: black;
      font-family: 'Rubik', sans-serif;
      .country.highlight {
        background-color: #7c4dff;
        color: black;
        font-family: 'Rubik', sans-serif;
      }
      .country {
        .dial-code {
          color: black;
        }
        &:hover {
          background-color: #7c4dff;
        }
      }
    }
    .selected-flag {
      &:hover {
        background-color: white;
        color: black;
        font-family: 'Rubik', sans-serif;
      }
      &:focus {
        background-color: white;
        color: black;
        font-family: 'Rubik', sans-serif;
      }
    }
    .flag-dropdown.open {
      .selected-flag {
        background-color: white;
      }
    }
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    font-size: 14px;
    padding: 0px;
    font-family: 'Rubik', sans-serif;
    padding-left: 0.2em;
  }
  .react-datepicker-wrapper {
    width: auto;
  }
  .react-datepicker-popper {
    z-index: 3;
  }
}
