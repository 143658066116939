@import 'themes/variables';

.main {
  display: flex;
  padding: 2em 5em;
  flex-direction: column;
  width: 60%;
  overflow: auto;
  @media (max-width: 1260px) {
    width: 70%;
  }
  @media (max-width: 959px) {
    width: 70%;
  }
  @media (max-width: 600px) {
    width: 100%;
    padding: 2em 1em;
  }
}

.form {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-right: 2em;
  @media (max-width: 600px) {
    padding-right: 0.75em;
  }
}

.heading {
  font-size: 18px;
  margin-bottom: 1em;
}

.fieldMargin {
  margin-top: 2em;
  margin-left: 1.5em !important;
  @media (max-width: 600px) {
    margin-left: 1em !important;
  }
  
}

.nameField {
  margin-left: 1.5em !important;
  @media (max-width: 600px) {
    margin-left: 1em !important;
  }
}

.label {
  font-size: 15px !important;
  color: var(--blueGray) !important;
  font-family: var(--fontFamily) !important;
  white-space: pre-wrap !important;
}

.textField {
  margin-top: 0.5em !important;
}

.inputField {
  font-family: var(--fontFamily) !important;
  font-size: 14px !important;
  color: var(--color) !important;
  background: var(--background) !important;

  &:hover {
    border: 1px var(--boxBorder) !important;
  }
}

.buttonContainer {
  display: flex;
  margin-top: 2em;
  justify-content: flex-end;
}

.cancelButton {
  font-size: 14px !important;
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  background: transparent !important;
  color: var(--color) !important;
  border: 1px solid #83889e !important;
  margin-right: 1em !important;

  &:hover {
    background: transparent !important;
  }

  &:disabled {
    background: var(--disableButton) !important;
  }
}

.submitButtton {
  font-size: 14px !important;
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  background: transparent !important;
  color: var(--color) !important;
  border: 1px solid #83889e !important;
  background: var(--builderPurple) !important;

  &:hover {
    background: var(--builderPurple) !important;
  }

  &:disabled {
    cursor: default !important;
    opacity: 0.5;
    background: var(--builderPurple) !important;
  }
}

.issueLabel {
  background: var(--blueGray2);
  color: var(--boxBorder);
  padding: 4px 8px;
  border-radius: 12px;
  gap: 8px;
  font-family: var(--fontFamily);
}

.activeIssue {
  color: var(--builderPurple);
  border: 1px solid var(--builderPurple);
}

.activeBorder {
  padding-left: 1.5em;
  margin-left: 0em !important;
  border-left: 3px solid var(--builderPurpleA2);
  border-top-left-radius: 0.4em;
  border-bottom-left-radius: 0.4em;
  @media (max-width: 600px) {
    padding-left: 1em;
  }
}