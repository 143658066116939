@import 'themes/variables';

.dialogText {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 20px;
  color: var(--color);
}

.button {
  display: flex;
  padding: 2em 0em;
  justify-content: flex-end;
}

.sendInviteButton {
  margin-left: 1em;
  background: var(--builderSuccess);
  font-family: var(--fontFamily);
  font-weight: bold;
  color: black;
  text-transform: none;
  font-size: 14px !important;
  &:hover {
    background: var(--builderSuccess);
  }
  &:disabled {
    background-color: black;
    color: white;
  }
}

.dialogTitle {
  display: flex;
  justify-content: space-between;
  font-family: var(--fontFamily);
  font-weight: bold;
  align-items: center;
}

.title {
  font-size: 16px;
  letter-spacing: -0.02em;
}

.close {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  color: var(--blueGray);
}

.text {
  display: flex;
}

.placeholder {
  font-family: var(--fontFamily);
}

.tabs {
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 2em !important;
}

.paper {
  background: var(--background) !important;
  color: var(--color) !important;
  border-radius: 10px !important;
  border: 1px solid var(--boxBorder) !important;
  max-width: 555px !important;
}

@import 'themes/variables';

.participantDetail {
  margin-left: 0.5em;
  font-family: var(--fontFamily);
}

.initial {
  color: #b388ff;
  display: flex;
  width: 32px;
  height: 32px;
  padding: 5px 7px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #f3ebff;
  background: #f3ebff;
  font-weight: bold;
  font-family: var(--fontFamily);
}

.menuItem {
  display: flex;
  align-items: center;
}

.name {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.14px;
}

.email {
  color: var(--blueGray);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: -0.12px;
}

.added {
  color: var(--blueGray);
  font-family: var(--fontFamily);
  margin-bottom: 1em;
}
