@import 'themes/variables';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 79%;
}

.main {
  display: flex;
  width: 100%;
  height: 50%;
  padding: 0em 1em;
  flex-direction: column;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2em;
  margin: 0em 2em 2em 0em;
  // border: 1px solid var(--boxBorder);
  background: var(--background);
  align-items: center;
  height: 100px;
  width: 150px;
  border-radius: 4px;
  // &:hover {
  //   border: 1px solid var(--builderPurple);
  //   background: var(--hoverColor) !important;
  // }
  @media (max-width: 599px) {
    padding: 1em;
    margin: 0em 1em 1em 0em;
    justify-content: center;
    height: 100px;
  }
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  display: flex;
  font-size: 16px;
  margin-bottom: 2em;
  font-weight: bold;
}

.subtitle {
  display: flex;
  font-size: 14px;
  margin-bottom: 0.5em;
}

.description {
  display: flex;
  font-size: 13px;
  margin-bottom: 3em;
  color: var(--blueGray);
}

.text {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.btnClass {
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  background: var(--builderSuccess) !important;
  color: white !important;
  width: fit-content !important;

  &:hover {
    background: var(--builderSuccess) !important;
  }

  &:disabled {
    background: var(--disableButton) !important;
  }
}

.buttonColor {
  background: var(--builderPurple) !important;

  &:hover {
    background: var(--builderPurple) !important;
  }
}

.cardMain {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  @media (max-width: 599px) {
    height: auto;
  }
}

.welcomeMessage {
  padding: 2em 0em;
  font-size: 20px;
  font-weight: bold;
}

.icon {
  cursor: pointer;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  @media (max-width: 599px) {
    justify-content: center;
  }
}

.mobileText {
  @media (max-width: 599px) {
    font-size: 12px;
  }
}

.label {
  color: var(--blueGray);
}

.link {
  color: white;
}

.signIn {
  color: var(--builderPurpleA2);
  margin-left: 8px;
  text-decoration: underline;
}

.upcomingMeetingSection {
  display: flex;
  height: 50%;
}
