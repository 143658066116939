@import 'themes/variables';

.threeDota {
    display: flex;
    padding: 0.8em;
    background-color: var(--builderSuccess);
    border-radius: 4px;
    cursor: pointer;
}

.link {
  display: flex;
  background: var(--color) !important;
  padding: 0.65em;
  color: black;
  justify-content: center;
  border-radius: 4px;
  font-weight: bold;
  align-items: center;
  cursor: pointer;
}

.copyLink {
  margin: 0em 1em;
  background: var(--color) !important;
  display: flex;
  border-radius: 4px;
  cursor: pointer;
}

.icons {
  display: flex;
  margin-right: 0.5em;
}
