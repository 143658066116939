@import 'themes/variables';

.blueColor {
  color: var(--blueGray);
}

.fields {
  margin-bottom: 0.5em;
}

.data {
  margin-left: 0.5em;
}

.menuList {
  background: var(--background);
  color: var(--color);
  border: 1px solid var(--boxBorder);
  border-radius: 3px;
}

.buttonContainer {
  margin-top: 0.5em;
  display: flex;
}

.btnClass {
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  background: var(--builderSuccess) !important;
  color: white !important;
  width: fit-content;
  height: 36px;
  font-size: 14px !important;

  &:hover {
    background: var(--builderSuccess) !important;
  }

  &:disabled {
    background: var(--blueGray) !important;
    color: black !important;
  }
}

.cancelButton {
  background: #ff8a80 !important;
  margin-left: 0.5em !important;
  color: black !important;

  &:hover {
    background: #ff8a80 !important;
  }
}

.copyLinkButton {
  cursor: pointer;
  margin-right: 0.5em;
}

.joinButton {
  background: var(--builderPurple) !important;
  &:hover {
    background: var(--builderPurple) !important;
  }
}

.joinButtonMargin {
  margin-left: 0.5em !important;
}

.startButton {
  color: black !important;
  margin-left: 0.5em !important;
}

.editButton {
  background: var(--bottomBackground) !important;

  &:hover {
    background: var(--bottomBackground) !important;
  }
}
