@import 'themes/variables';

.dialogText {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: var(--color);
  white-space: pre-wrap;
}

.actionDialog {
  padding: 24px 24px !important;
  justify-content: flex-end !important;
}

.cancelButton {
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
  background: transparent !important;
  color: var(--color) !important;
  border: 1px solid var(--boxBorder) !important;

  &:hover {
    background: transparent !important;
  }

  &:disabled {
    background: var(--disableButton) !important;
  }
}

.joinButton {
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  text-transform: none !important;
  margin-left: 1em !important;
  background: var(--builderPurple) !important;
  color: white !important;
  font-size: 14px !important;

  &:hover {
    background: var(--builderPurple) !important;
  }

  &:disabled {
    cursor: default !important;
    opacity: 0.5;
    background: var(--builderPurple) !important;
  }
}

.label {
  font-size: 12px !important;
  font-weight: bold !important;
  color: var(--blueGray) !important;
  font-family: var(--fontFamily) !important;
}

.textField {
  margin-top: 0.5em !important;
}

.inputField {
  font-family: var(--fontFamily) !important;
  font-size: 14px !important;
  color: var(--color) !important;
  font-weight: bold !important;
  background: var(--background) !important;

  &::-webkit-input-placeholder {
    color: var(--color) !important;
  }

  &:-ms-input-placeholder {
    color: var(--color) !important;
  }

  &::-ms-input-placeholder {
    color: var(--color) !important;
  }
}

.lightLabel {
  color: var(--color) !important;
}

.button {
  background: var(--builderSuccess) !important;
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  color: black !important;
  text-transform: capitalize !important;

  &:hover {
    background: var(--builderSuccess) !important;
  }

  &:disabled {
    background: var(--disableButton) !important;
  }
}

.googleButton {
  margin-left: 0;
}
