@import 'themes/variables';

.container {
  height: 100%;
  width: 100%;
  display: flex;
  position: fixed;
  font-family: var(--fontFamily);
  background: var(--themeColor);
  color: var(--color);
}

.innerContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}

.joinScreen {
  height: 100%;
}

.mobileContainer {
  position: fixed;
  width: 100%;
  flex-direction: column;
}

.mobileSidebar {
  flex-direction: row;
  border-bottom: 1px solid var(--borderColor);
  padding: 0.5em;
  display: none;
}
