@import 'themes/variables';

.textField {
  margin-top: 0.5em !important;
  width: 100%;
}

.inputField {
  font-family: var(--fontFamily) !important;
  font-size: 14px !important;
  color: var(--color) !important;
  background: var(--background) !important;

  &:hover {
    border: 1px var(--boxBorder) !important;
  }
}

.menuList {
  background: var(--background) !important ;
  color: var(--color) !important;
  border: 1px solid var(--boxBorder) !important;
  border-radius: 3px !important;
}

.menuItems {
  color: var(--color) !important;
  justify-content: center !important;
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  font-size: 0.9rem !important;

  &:hover {
    background: var(--builderPurple) !important;
  }
}

.buttonBackground {
  background: var(--builderPurple) !important;
  color: white !important;
}
