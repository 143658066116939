@import 'themes/variables';

.main {
  display: flex;
  height: 84%;
  width: 100%;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
}

.label {
  font-size: 12px !important;
  color: var(--blueGray) !important;
  font-family: var(--fontFamily) !important;
}

.actionDialog {
  padding: 1.3em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.cancelButton {
  font-size: 14px !important;
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  background: transparent !important;
  color: var(--color) !important;
  border: 1px solid #83889e !important;
  width: 100% !important;

  &:hover {
    background: transparent !important;
  }

  &:disabled {
    background: var(--disableButton) !important;
  }
}

.joinButton {
  background: var(--builderSuccess) !important;
  color: black !important;
  font-size: 14px !important;
  margin-left: 1em !important;
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  width: 100% !important;

  &:hover {
    background: var(--builderSuccess) !important;
  }

  &:disabled {
    background: var(--blueGray) !important;
    color: white;
  }
}

.textField {
  margin-top: 0.5em !important;
}

.inputField {
  font-family: var(--fontFamily) !important;
  font-size: 14px !important;
  color: var(--color) !important;
  background: var(--background) !important;

  &:hover {
    border: 1px var(--boxBorder) !important;
  }
}

.fieldMargin {
  margin-top: 1.5em !important;
}

.frequencyField {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

.frequency {
  display: flex !important;
  justify-content: space-between !important;
  margin-top: 0.5em !important;
  margin-left: 0.7rem;
  margin-bottom: 5px;
}

.date {
  display: flex;
  background: var(--background);
  padding: 0.8em 0.7em;
  border-radius: 4px;
  border: 1px solid var(--boxBorder);
  color: var(--color);
  font-family: var(--fontFamily);

  &:disabled {
    color: gray;
  }
}

.initialField {
  display: flex;
  flex-direction: column;
}

.recurringCOntainer {
  display: flex;
  width: 60%;
  flex-direction: row;
  justify-content: space-between;
}

.frequencyStyle {
  display: flex;
  flex-direction: column;
}

.startDate {
  padding: 0.81em 0.7em;
  margin-right: 0.3em;
  width: 100% !important;
}

.endDate {
  margin-top: -0.1em;
  padding: 0.95em 0.7em;
  width: 100%;
}

.meetingButtons {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  margin-top: 1em;
  width: 100%;
}
