@import 'themes/variables';

.main {
  display: flex;
  height: 84%;
  width: 100%;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
}

.filterLabel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.viewLessLabel {
  color: var(--builderPurpleA2);
}

.logOutBtn {
  background: var(--builderSuccess) !important;
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  color: black !important;
  text-transform: capitalize !important;

  &:hover {
    background: var(--builderSuccess) !important;
  }
}

.mainBtn {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1em;
}

.meetingCardContainer {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  overflow: auto;
}

.meetingCardContainer::-webkit-scrollbar {
  width: 0.5em; /* Set the width of the scrollbar */
}

.meetingCardContainer::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.noMeeting {
  color: var(--builderPurpleA2);
  padding: 0em 1em;
}

.radioGrp {
  padding: 0.5em 1em;
}

.radioColor {
  color: #83889e !important;
}

.radioActiveColor {
  color: var(--builderPurpleA2) !important;
}

.day {
  margin: 0em 1em;
  border-bottom: 1px solid var(--boxBorder);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tabClass {
  cursor: pointer;
  color: var(--blueGray);
  display: flex;
  height: 100%;
  align-items: center;
  font-weight: bold;
}

.activeTab {
  color: var(--builderPurpleA2);
  border-bottom: 2px solid var(--builderPurpleA2);
}