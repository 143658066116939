@import 'themes/variables';

.participantDetail {
  margin-left: 0.5em;
  font-family: var(--fontFamily);
}

.initial {
  color: #b388ff;
  display: flex;
  width: 32px;
  height: 32px;
  padding: 5px 7px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #f3ebff;
  background: #f3ebff;
  font-weight: bold;
}

.menuItem {
  display: flex;
  align-items: center;
}

.name {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.14px;
}

.email {
  color: var(--blueGray5);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: -0.12px;
}

.inputField {
  background: var(--background) !important;
  color: white !important;
}