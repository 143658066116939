@import 'themes/variables';

.main {
  display: flex;
  height: 82%;
  width: 100%;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 1em 0em;
}

.filterLabel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.viewMoreLabel {
  color: var(--builderPurpleA2);
}

.viewMoreDisabled {
  color: var(--blueGray);
}

.logOutBtn {
  background: var(--builderSuccess) !important;
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  color: black !important;
  text-transform: capitalize !important;

  &:hover {
    background: var(--builderSuccess) !important;
  }
}

.mainBtn {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1em;
}

.meetingCardContainer {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  height: inherit
}

.cardParent {
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-bottom: 0.75em;
}

.recordingClass {
  height: 81%;
}

.noMeeting {
  color: var(--builderPurpleA2);
  padding: 0em 1em;
}

.btnClass {
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  background: var(--builderSuccess) !important;
  color: black !important;
  font-size: 14px !important;
  width: fit-content;
  align-self: center;

  &:hover {
    background: var(--builderSuccess) !important;
  }

  &:disabled {
    // background: var(--disableButton) !important;
    background: var(--blueGray) !important;
    color: white;
  }
}
