@import 'themes/variables';

.endButtons {
  display: flex;
  margin-right: 1em;
  cursor: pointer;
}

.endButtonStyle {
  padding: 0.25em;
  border-radius: 4px;
}

.buttonBackground {
  background: var(--builderSuccess);
  color: white;
}
