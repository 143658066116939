html,
body,
#root {
  height: 100%;
  font-size: 14px;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.2) rgba(255, 255, 255, 0.1);
}

::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4em;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}
