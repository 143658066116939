@media (max-height: 320px) {
  .MuiDialogTitle-root,
  .MuiDialogActions-root,
  .MuiDialogContent-root {
    padding: 12px !important;
  }
  .MuiButton-root {
    padding: 2px 14px !important;
  }
  .MuiOutlinedInput-input {
    padding: 7px !important;
  }
  .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .tileHeight {
    height: 100%;
  }
  .background360 {
    position: relative;
  }
  .topBarHeight {
    height: 12% !important;
  }
  .bottomMenuWidth {
    width: 375px !important;
  }
  .videoIconMargin360 {
    margin-right: 0.45em !important;
  }
  .sendEmailButton {
    padding: 1em 0em !important;
  }
  .tabs {
    margin-bottom: 1em !important;
  }
  .chat {
    height: 60% !important;
  }
  .chatInput {
    height: 40% !important;
  }
  .container360 {
    padding: 0.5em !important;
  }
}
