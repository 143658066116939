@import 'themes/variables';

.btnClass {
  background: var(--builderSuccess) !important;
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  color: black !important;
  text-transform: capitalize !important;
  margin-left: 0.5em !important;
  font-size: 12px !important;

  &:disabled {
    background: var(--blueGray) !important;
    color: white;
  }

  &:hover {
    background: var(--builderSuccess) !important;
  }
}

.card {
  display: flex;
  flex-direction: column;
  padding: 1em;
  border: 1px solid var(--boxBorder);
  background: var(--background);
  margin: 0.5em 1em;
  border-radius: 4px;
}

.meetingTime {
  display: flex;
  color: var(--infoBorder);
  border: 1px solid var(--infoBorder);
  width: fit-content;
  border-radius: 10px;
  padding: 0.2em 0.5em;
  font-size: 12px;
  background-color: var(--themeColor);
}

.hostlabel {
  color: var(--blueGray);
}

.meetingNameMain {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5em;
}

.dots {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.meetingTimeMargin {
  margin-right: 0.5em;
}

.hostContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.menuList {
  background: var(--background) !important;
  color: var(--color) !important;
  border: 1px solid var(--boxBorder) !important;
  border-radius: 3px;
}

.menuItems {
  justify-content: center !important;
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  font-size: 0.9rem !important;
  color: var(--color) !important;
  flex-direction: column;
  padding: 0.5em 1em;
  width: 250px;

  &:hover {
    background-color: transparent !important;
  }
}

.fields {
  margin-bottom: 0.5em;
}

.label {
  color: var(--blueGray);
}

.value {
  color: white;
  margin-left: 0.5em;
  text-transform: capitalize;
}

.buttonContainer {
  display: flex;
  margin-top: 0.75em;
  justify-content: center;
}

.editBtn {
  background: transparent !important;
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  color: white !important;
  text-transform: capitalize !important;
  margin-left: 0.5em !important;
  font-size: 12px !important;
  border: 1px solid var(--blueGray) !important;
}

.cancelBtn {
  background: var(--red4) !important;
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  color: black !important;
  text-transform: capitalize !important;
  margin-left: 0.5em !important;
  font-size: 12px !important;

  &:hover {
    background: var(--red4) !important;
  }
}

.joinButton {
  background: var(--builderPurpleA2) !important;

  &:disabled {
    background: var(--blueGray) !important;
    color: white;
  }

  &:hover {
    background: var(--builderPurpleA2) !important;
  }
}

.closeIcon {
  margin-top: -0.5em;
  color: var(--blueGray);
  cursor: pointer;
}
