@import 'themes/variables';

.main {
  display: flex;
  width: 75%;
  padding: 0em 9em;
  flex-direction: column;
  border-right: 1px solid var(--borderColor);

  @media (max-width: 1279px) {
    padding: 0em 5em;
  }

  @media (max-width: 599px) {
    width: 100%;
    padding: 0em 3em;
  }
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2em;
  margin: 0em 2em 2em 0em;
  border: 1px solid var(--boxBorder);
  background: var(--background);
  align-items: center;
  height: 150px;
  width: 180px;
  border-radius: 4px;
  &:hover {
    border: 1px solid var(--builderPurple);
    background: var(--hoverColor) !important;
  }
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  display: flex;
  font-size: 16px;
  margin-bottom: 2em;
  font-weight: bold;
}

.subtitle {
  display: flex;
  font-size: 14px;
  margin-bottom: 0.5em;
}

.description {
  display: flex;
  font-size: 13px;
  margin-bottom: 3em;
  color: var(--blueGray);
}

.text {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.btnClass {
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  background: var(--builderSuccess) !important;
  color: white !important;
  width: fit-content !important;

  &:hover {
    background: var(--builderSuccess) !important;
  }

  &:disabled {
    background: var(--disableButton) !important;
  }
}

.buttonColor {
  background: var(--builderPurple) !important;

  &:hover {
    background: var(--builderPurple) !important;
  }
}

.cardMain {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
}

.welcomeMessage {
  padding: 2em 0em;
  font-size: 20px;
  font-weight: bold;
}

.icon {
  cursor: pointer;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  @media (max-width: 599px) {
    flex-direction: column;
  }
}
