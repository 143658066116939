@import 'themes/variables.scss';

.text {
  display: flex !important;
}

.dialogActionClass {
  padding: 0px !important;
}

.actionDialog {
  padding: 24px 24px !important;
}

.paper {
  background: var(--background) !important;
  color: var(--color) !important;
  border-radius: 10px !important;
  border: 1px solid var(--boxBorder) !important;
}

.btnClass {
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  background: var(--builderSuccess) !important;
  color: black !important;

  @media (max-width: 599px) {
    width: -webkit-fill-available;
  }
}

.backBtnClass {
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  background: transparent !important;
  color: white !important;
  border: 1px solid var(--boxBorder) !important;
  margin-right: 8px !important;

  @media (max-width: 599px) {
    width: -webkit-fill-available;
    margin-right: 0px !important;
    margin-bottom: 8px !important;
  }
}

.action {
  padding: 24px !important;
  align-self: center !important;
  margin-left: 14px !important;

  @media (max-width: 599px) {
    flex-direction: column;
  }
}
