@import 'themes/variables';

.dialogTitle {
  display: flex !important;
  justify-content: center !important;
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
}

.dialogText {
  font-family: var(--fontFamily) !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 13px !important;
  line-height: 20px !important;
  color: var(--color) !important;
  white-space: pre-wrap !important;
}

.endMeetingText {
  text-align: center !important;
  font-size: 16px !important;
  padding-bottom: 1em !important;

  @media (max-width: 599px) {
    font-size: 13px !important;
  }
}

.title {
  font-size: 16px;
  letter-spacing: -0.02em;
}

.text {
  display: flex;
}

.safariText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.paper {
  background: var(--background) !important;
  color: var(--color) !important;
  border-radius: 10px !important;
  border: 1px solid var(--boxBorder) !important;
  min-height: 420px !important;
  max-width: 1000px !important;
}

.dialogAction {
  padding: 24px 24px !important;
  justify-content: center !important;
}

.btnClass {
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  background: var(--builderSuccess) !important;
  color: black !important;

  &:hover {
    background: var(--builderSuccess) !important;
  }

  &:disabled {
    background: var(--disableButton) !important;;
  }
}
