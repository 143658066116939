@import 'themes/variables';

.main {
  display: flex;
  width: 25%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  letter-spacing: -0.02em;
  background: var(--background);
  @media (max-width: 599px) {
    display: none;
  }
}

.header {
  display: flex;
  width: 100%;
  height: 3em;
  align-items: center;
  padding: 1.5em;
  letter-spacing: -0.02em;
  font-weight: bold;
  border-bottom: 1px solid var(--borderColor);
}

.scheduleIcon {
  display: flex;
  margin-right: 0.5em;
}

.root {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.noMeetings {
  display: flex;
  justify-content: center;
  padding: 1.5em;
  font-size: 16px;
}

.footer {
  display: flex;
  height: 9%;
  border-top: 1px solid var(--borderColor);
  width: 100%;
  align-items: center;
  padding: 1.5em;
  letter-spacing: -0.02em;
  font-weight: bold;
}

.btnClass {
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  margin-top: 0em !important;
  width: 100% !important;
  background: var(--builderPurple) !important;
  color: white !important;
  margin-left: 0em !important;

  &:hover {
    background: var(--builderPurple) !important;
  }

  &:disabled {
    background: var(--disableButton) !important;
  }
}

.content {
  display: flex;
  flex-direction: column;
  height: 91%;
}

.radioGrp {
  display: flex !important;
  flex-direction: row !important;
  padding: 0.5em 1em; 
}

.day {
  padding: 1em 0em 0em;
  margin: 0em 1em;
  border-bottom: 1px solid var(--boxBorder);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tabClass {
  cursor: pointer;
  color: var(--blueGray);
  display: flex;
  height: 100%;
  align-items: center;
  font-weight: bold;
}

.activeTab {
  color: var(--builderPurpleA2);
  border-bottom: 2px solid var(--builderPurpleA2);
}
