@import 'themes/variables';

.box {
  font-family: var(--fontFamily) !important;
  font-size: 14px !important;
  color: var(--color) !important;
  background: var(--background) !important;
  border-radius: 4px !important;
  border: 1px solid var(--boxBorder) !important;
  padding: 4px 8px;
  margin-top: 0.5em;

  &:hover {
    border: 1px solid var(--boxBorder) !important;
  }
}

.inputBase {
  flex-grow: 1;
  font-family: var(--fontFamily) !important;
}

.chip {
  size: small;
  background-color: var(--builderPurple) !important;
  color: white !important;
  margin: 2px !important;
  padding: 2px !important;
  border-radius: 4px !important;
  font-family: var(--fontFamily) !important;
}

.errorChip {
  background-color: var(--error) !important;
  // color: black !important;
}

.closeButton {
  color: white !important;
  background-color: transparent !important;
}

.popper {
  background-color: var(--themeColor) !important;
  border: 1px solid var(--boxBorder) !important;
  max-height: 120px;
  overflow-y: auto;
  z-index: 9999;
}

.listItem {
  cursor: pointer;
  color: white;
  font-family: var(--fontFamily) !important;
  &:hover {
    background-color: var(--background) !important;
  }
}

.errorText {
  font-family: var(--fontFamily) !important;
  color: red;
  margin: 0 8px;
}
