@import 'themes/variables';

.main {
  display: flex;
  width: 100%;
  padding: 0 9em;
  flex-direction: column;

  @media (max-width: 959px) {
    padding: 0 4em;
  }

  @media (max-width: 599px) {
    padding: 0 2em;
  }
}

.head {
  color: var(--color);
  font-family: var(--fontFamily);
  border: none; // `1px solid ${theme.boxBorder}`;
  font-size: 14px;
  background: var(--background);
  text-align: center;
}

.headFont {
  font-size: 18px;
}

.link {
  display: flex;
  background: var(--builderSuccess);
  padding: 1em;
  color: black;
  justify-content: center;
  border-radius: 4px;
  font-weight: bold;
  align-items: center;
}

.inputField {
  font-family: var(--fontFamily) !important;
  font-size: 1em !important;
  font-weight: bold !important;
  color: var(--color) !important;
  background: var(--background) !important;
}

.records {
  display: flex;
  font-size: 16px;
  padding: 12px;
}

.searchContainer {
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
}

.meetingTitle {
  margin: 0 1em 0 0;
  display: flex;
  align-items: center;
}

.meetingTime {
  margin: 0 1em;
  display: flex;
  align-items: center;
}

.date {
  background: var(--background) !important;
  padding: 0.8em 0.7em !important;
  border-radius: 4px !important;
  border: 1px solid var(--boxBorder) !important;
  color: var(--color) !important;
  font-family: var(--fontFamily) !important;
}

.btnClass {
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  background: var(--builderSuccess) !important;
  color: black !important;

  &:hover {
    background: var(--builderSuccess) !important;
  }

  &:disabled {
    background: var(--blueGray) !important;
    color: white;
  }
}