@import 'themes/variables';

.dialogText {
  font-family: var(--fontFamily) !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 13px !important;
  line-height: 20px !important;
  color: var(--color) !important;
  white-space: pre-wrap !important;
  text-align: center !important;
}

.endMeetingText {
  text-align: center !important;
  font-size: 16px !important;
  padding-bottom: 1em !important;

  @media screen and (max-width: 599px) {
    font-size: 13px !important;
  }
}
