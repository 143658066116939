@import 'themes/variables.scss';

.actionDialog {
  padding: 24px 24px !important;
}

.paper {
  background: var(--background) !important;
  color: var(--color) !important;
  border-radius: 10px !important;
  border: 1px solid var(--boxBorder) !important;
  max-width: 700px !important;
}

.mobilePaper {
  max-width: 300px !important;
}

.button {
  background: var(--builderSuccess) !important;
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  color: black !important;
  text-transform: capitalize !important;
  &:hover {
    background: var(--builderSuccess) !important;
  }
}

.title1 {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.02em;
}

.title2 {
  margin-top: 0.5em;
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  // align-items: center;
  text-align: start;
  padding: 0em 2em;
  letter-spacing: -0.01em;
  flex-direction: column;
}

.title1Mobile {
  font-size: 17px;
}

.title2Mobile{
  padding: 0em 0.5em;
}

.actionDialogMobile {
  padding: 0px 24px 12px !important;
}