//For Light Mode
:root {
  --background: white;
  --borderColor: #dfe0e6;
  --themeColor: #f8f9fa;
  --builderPurple: #7c4dff;
  --builderPurpleA2: #b388ff;
  --builderSuccess: #00da6f;
  --fontFamily: 'Rubik', sans-serif;
  --blueGray: #83889e;
  --color: black;
  --activeBackground: #f4eaff;
  --boxBorder: #dfe0e6;
  --error: #df1f1f;
  --border: #dfe0e6;
  --bottomBackground: #f8f9fa;
  --disableButton: rgba(0, 0, 0, 0.12);
  --hoverColor: #353535;
  --blueGray2: #ecedf0;
  --blueGray5: #c0c3ce;
  --infoBorder: #b6cff3;
  --red4: #ff8a80;
  --red: #ff5546;
}

//For Dark Mode
[data-theme='dark'] {
  --background: #2e2e2e;
  --borderColor: rgba(255, 255, 255, 0.1);
  --themeColor: #242424;
  --builderPurple: #7c4dff;
  --builderPurpleA2: #b388ff;
  --builderSuccess: #00da6f;
  --fontFamily: 'Rubik', sans-serif;
  --blueGray: #9b9fb1;
  --color: white;
  --activeBackground: #7c4dff;
  --boxBorder: #676b7e;
  --error: #df1f1f;
  --border: rgba(255, 255, 255, 0.07);
  --bottomBackground: rgba(255, 255, 255, 0.07);
  --disableButton: rgba(0, 0, 0, 0.12);
  --hoverColor: #353535;
  --blueGray2: #ecedf0;
  --blueGray5: #c0c3ce;
  --infoBorder: #b6cff3;
  --red4: #ff8a80;
  --red: #ff5546;
}
