@import 'themes/variables';

.main {
  display: flex;
  width: 100%;
  padding: 0 9em;
  flex-direction: column;

  @media (max-width: 959px) {
    padding: 0 4em;
  }

  @media (max-width: 599px) {
    padding: 0 2em;
  }
}

.head {
  color: var(--color) !important;
  font-family: var(--fontFamily) !important;
  border: none; // 1px solid var(--boxBorder);
  font-size: 14px;
  background: var(--background) !important;
  text-align: center;
  border-bottom: none !important;
}

.headFont {
  font-size: 18px !important;
}

.link {
  display: flex;
  background: var(--builderSuccess);
  padding: 1em;
  color: black;
  justify-content: center;
  border-radius: 4px;
  font-weight: bold;
  align-items: center;
}

.records {
  display: flex;
  font-size: 16px;
  padding: 12px;
}

.showMore {
  margin-top: 1em;
  display: flex;
  justify-content: flex-end;
}

.btnClass {
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  background: var(--builderSuccess) !important;
  color: black !important;

  &:hover {
    background: var(--builderSuccess) !important;
  }

  &:disabled {
    background: var(--blueGray) !important;
    color: white;    
  }
}