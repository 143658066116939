@import 'themes/variables';

.dialogText {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 20px;
  color: var(--color);
}

.button {
  display: flex;
  padding: 2em 0em;
  justify-content: flex-end;
}

.sendInviteButton {
  margin-left: 1em;
  background: var(--builderSuccess);
  font-family: var(--fontFamily);
  font-weight: bold;
  color: black;
  text-transform: none;
  font-size: 14px !important;
  &:hover {
    background: var(--builderSuccess);
  }
  &:disabled {
    background-color: black;
    color: white;
  }
}

.dialogTitle {
  display: flex;
  justify-content: space-between;
  font-family: var(--fontFamily);
  font-weight: bold;
  align-items: center;
}

.title {
  font-size: 16px;
  letter-spacing: -0.02em;
}

.close {
  cursor: pointer;
  // position: absolute;
  display: flex;
  // width: 100%;
  justify-content: flex-end;
  // margin-right: 2em;
  color: var(--blueGray);
  font-size: 12px;
}

.text {
  display: flex;
}

.placeholder {
  font-family: var(--fontFamily);
}

.tabs {
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 2em !important;
}

.paper {
  background: var(--background) !important;
  color: var(--color) !important;
  border-radius: 10px !important;
  border: 1px solid var(--boxBorder) !important;
  max-width: 555px !important;
}

.box {
  font-family: var(--fontFamily) !important;
  font-size: 14px !important;
  color: var(--color) !important;
  background: var(--background) !important;
  border-radius: 4px !important;
  border: 1px solid var(--boxBorder) !important;
  padding: 4px 8px;
  margin-top: 0.5em;

  &:hover {
    border: 1px solid var(--boxBorder) !important;
  }

  @media (max-width: 599px) {
    max-height: 38px;
  }
}

.inputBase {
  flex-grow: 1;
  font-family: var(--fontFamily) !important;
}

.chip {
  size: small;
  background-color: var(--builderPurple) !important;
  color: white !important;
  margin: 2px !important;
  padding: 2px !important;
  border-radius: 4px !important;
  font-family: var(--fontFamily) !important;
}

.errorChip {
  background-color: var(--error) !important;
  // color: black !important;
}

.closeButton {
  color: white !important;
  background-color: transparent !important;
}

.popper {
  background-color: var(--themeColor) !important;
  border: 1px solid var(--boxBorder) !important;
  max-height: 120px;
  overflow-y: auto;
  z-index: 9999;
}

.listItem {
  cursor: pointer;
  color: white;
  font-family: var(--fontFamily) !important;
  &:hover {
    background-color: var(--background) !important;
  }
}

.errorText {
  font-family: var(--fontFamily) !important;
  color: red;
  margin: 0 8px;
  font-size: 13px;
}

.delegateBtn {
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  background: var(--builderSuccess) !important;
  color: black !important;
  font-size: 14px !important;
  width: fit-content !important;

  &:hover {
    background: var(--builderSuccess) !important;
  }

  &:disabled {
    // background: var(--disableButton) !important;
    background: var(--blueGray) !important;
    color: white;
  }

  @media (max-width: 599px) {
    font-size: 12px !important;
    width: -webkit-fill-available;
  }
}

.emailLabel {
  color: var(--blueGray);
  font-weight: bold;
  font-family: var(--fontFamily);
}
