.main {
  display: flex;
  width: 100%;
  padding: 0 9em;
  flex-direction: column;
  justify-content: center;
  height: 90%;
  margin: auto 0;

  @media (max-width: 959px) {
    padding: 0 4em;
  }

  @media (max-width: 599px) {
    padding: 0 2em;
  }
}
