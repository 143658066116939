@import 'themes/variables';

.dialogTitle {
  display: flex;
  justify-content: space-between;
  font-family: var(--fontFamily);
  font-weight: bold;
}

.title {
  font-size: 18px;
  letter-spacing: -0.02em;
  color: var(--color);
}

.close {
  cursor: pointer;
  font-size: 14px;
  align-self: center;
  color: var(--blueGray);
}

.text {
  display: flex;
}

.paper {
  background: var(--themeColor) !important;
  color: var(--color) !important;
  border-radius: 10px !important;
  border: 1px solid var(--boxBorder) !important;
  min-width: 600px !important;
}
