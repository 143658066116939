@import 'themes/variables';

.meetingLink {
  cursor: pointer;
  text-decoration: underline;
}

.userIcon {
  cursor: pointer;
}

.display {
  display: none;
}

.button {
  background: var(--builderSuccess) !important;
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  color: black !important;
  text-transform: capitalize !important;

  &:hover {
    background: var(--builderSuccess) !important;
  }
}

.logOutButton {
  background: var(--red4) !important;
  &:hover {
    background: var(--red4) !important;
  }
}

.menuList {
  background: var(--background) !important;
  color: var(--color) !important;
  border: 1px solid var(--boxBorder) !important;
  border-radius: 3px;
}

.menuItems {
  justify-content: center !important;
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  font-size: 0.9rem !important;
  color: var(--color) !important;
  flex-direction: column;

  &:hover {
    background-color: transparent !important;
  }
}

.participantDetail {
  margin-left: 0.5em;
  font-family: var(--fontFamily);
}

.initial {
  color: #b388ff;
  display: flex;
  width: 32px;
  height: 32px;
  padding: 5px 7px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #f3ebff;
  background: #f3ebff;
  font-weight: bold;
}

.menuItem {
  display: flex;
  align-items: center;
  padding: 1em;
  border: 1px solid var(--boxBorder);
  border-radius: 4px;
}

.name {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.14px;
}

.email {
  color: var(--blueGray5);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: -0.12px;
}

.profile_picture {
  width: 32px;
  height: 32px;
  border-radius: 20px;
}

.main {
  display: flex;
  flex-direction: column;
  margin: 1em;
  width: 250px;
}

.delegateMain {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.7em;
  align-items: center;
  cursor: pointer;
}

.delegateListMain {
  display: flex;
  flex-direction: row;
  margin: 0.7em 0em 0em;
  align-items: center;
  cursor: pointer;
}

.AAAbutton {
  background: var(--builderSuccess) !important;
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  color: black !important;
  text-transform: capitalize !important;
  text-decoration: none;
  padding: 0.5em;
  margin-top: 0.5em;
  border-radius: 4px;
  width: -webkit-fill-available;
  text-align: center;
  &:hover {
    background: var(--builderSuccess) !important;
  }
}