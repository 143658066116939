@import 'themes/variables';

.main {
  display: flex;
  width: 100%;
  padding: 0em 9em;
  flex-direction: column;

  @media (max-width: 959px) {
    padding: 0em 4em;
  }

  @media (max-width: 599px) {
    padding: 0em 2em;
  }
}

.cardMain {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  height: 100%;
  justify-content: space-between;
  overflow: auto;
}

.day {
  // padding-bottom: 0.5em;
  margin-bottom: 1.5em;
  margin-right: 1em;
  border-bottom: 1px solid var(--borderColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.meetingContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.cardContainer {
  display: flex;
  flex-direction: column;
}

.meetingHeading {
  padding: 1em 0em 1em 0em;
  font-size: 20px;
  font-weight: bold;
}

.noMeetings {
  font-size: 16px;
}

.radioGrp {
  display: flex !important;
  flex-direction: row !important;
  // padding: em 1em;
}

.tabClass {
  cursor: pointer;
  color: var(--blueGray);
  display: flex;
  height: 100%;
  align-items: center;
  font-weight: bold;
}

.activeTab {
  color: var(--builderPurpleA2);
  border-bottom: 2px solid var(--builderPurpleA2);
}
