@import 'themes/variables';

.iframeStyle {
  border: 2px solid var(--boxBorder) !important;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.videoStyle {
  // border: 2px solid var(--boxBorder) !important;
  height: 80%;
  width: 100%;
  border-radius: 10px;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.meetingDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
  margin-bottom: 1em;
  @media (max-width: 599px) {
    flex-direction: column;
    width: 100%;
    padding: 0.2em;
    font-size: 12px;
    margin: 1em 0em 0em 3em;
  }
}

.header {
  display: flex;
  flex-direction: column;
}

.value {
  @media (max-width: 599px) {    
    margin-left: 1em;
  }
}
