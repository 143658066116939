@import 'themes/variables.scss';

// .main {
//   display: flex;
//   flex-direction: row;
//   height: 6%;
//   align-items: center;
//   justify-content: space-between;
//   background: var(--background);
//   border-bottom: 1px solid var(--borderColor);
//   width: 100%;
// }

.topBar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.homeIcon {
  display: flex;
  margin: 0.15em 0.5em 0.2em 1em;
}

.main {
  display: flex;
  border-bottom: 1px solid var(--boxBorder);
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  background-color: var(--background);
}

.text {
  display: flex;
  color: var(--builderPurpleA2);
  text-decoration: none;
}

.feedback {
  cursor: pointer;
  margin-bottom: -0.5em
}