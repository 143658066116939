@import 'themes/variables';

.card {
  display: flex;
  flex-direction: column;
  margin-right: 1em;
  width: 47%;
  padding: 1.5em;
  border: 1px solid var(--boxBorder);
  background: var(--background);
  margin-bottom: 1.5em;
  white-space: nowrap;
  overflow: auto;
  border-radius: 4px;
  @media (max-width: 599px) {
    width: 100%;
  }
}

.menuList {
  background: var(--background);
  color: var(--color);
  border: 1px solid var(--boxBorder);
  border-radius: 3px;
}

.dialog {
  width: 100%;
  border: none;
  padding: 0em 1em;
  margin-right: 0em;
}
