@import 'themes/variables';

.btnClass {
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  background: var(--builderSuccess) !important;
  color: black !important;

  &:hover {
    background: var(--builderSuccess) !important;
  }

  &:disabled {
    background: var(--disableButton) !important;
  }
}

.main {
  display: flex;
  padding: 1.5em;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.container {
  display: flex;
  margin-top: 1em;
}

.captcha {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.button {
  margin-top: 1em !important;
  margin-left: 0em !important;
}

.inputField {
  font-family: var(--fontFamily);
  font-size: 14px;
  color: var(--color);
  font-weight: bold;
  background: var(--background);
}

.resendButton {
  background: none !important;
  color: white !important;
  box-shadow: none !important;
  text-decoration-line: underline !important;

  &:hover {
    background: none !important;
    color: white !important;
    box-shadow: none !important;
    text-decoration-line: underline !important;
  }

  &:disabled {
    background: none !important;
    color: gray !important;
    box-shadow: none !important;
    text-decoration-line: underline !important;
  }
}

.seconds {
  display: flex;
  align-items: center;
  margin-left: 1em;
  max-width: 8em;
}

.buttons {
  display: flex;
  flex-direction: row;
  margin-top: 1em;
}

.otpField {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.reCaptcha {
  display: flex;
  justify-content: center;

  @media (max-width: 1279px) {
    transform: scale(0.8, 0.8);
    transform-origin: 0 1;
  }

  @media (max-width: 959px) {
    transform: scale(0.7, 0.8);
    transform-origin: 0 1;
  }

  @media (max-width: 599px) {
    transform: scale(0.5, 0.8);
    transform-origin: 0 1;
  }
}

.optField {
  width: 2.5em !important;
  height: 2em;
  background: transparent;
  border: 1px solid var(--borderColor);
  color: var(--color);
  font-weight: bold;
  font-family: var(--fontFamily);

  @media (max-width: 1279px) {
    width: 2em !important;
  }
}
