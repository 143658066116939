@import 'themes/variables';

.dialogTitle {
  display: flex;
  justify-content: space-between;
  font-family: var(--fontFamily);
  font-weight: bold;
}

.recordingIcon {
  margin-right: 0.2em;
  margin-top: 0.3em;
  display: flex;
}

.title {
  font-size: 16px;
  letter-spacing: -0.02em;
  color: var(--color);
}

.close {
  cursor: pointer;
  font-size: 12px;
  align-self: center;
  color: var(--color);
}

.text {
  display: flex;
}
