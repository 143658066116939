@import 'themes/variables';

.participantDetail {
  margin-left: 0.5em;
  font-family: var(--fontFamily);
}

.initial {
  color: #b388ff;
  display: flex;
  width: 32px;
  height: 32px;
  padding: 5px 7px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #f3ebff;
  background: #f3ebff;
  font-weight: bold;
  font-family: var(--fontFamily);
}

.menuItem {
  display: flex;
  align-items: center;
}

.name {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.14px;
}

.email {
  color: var(--blueGray);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: -0.12px;
}

.added {
  color: var(--blueGray);
  font-family: var(--fontFamily);
  margin-bottom: 1em;
}

.remove {
  color: var(--builderPurpleA2);
  text-decoration: underline;
  cursor: pointer;
  margin-left: 4em;
  @media (max-width: 599px) {
    margin-left: 0em;
  }
}

.addedPeople {
  display: flex;
  width: 50%;
  align-items: center;
  @media (max-width: 599px) {
    justify-content: space-between;
    width: 100%;
  }
}
