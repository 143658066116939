@import 'themes/variables';

.head {
  color: var(--color) !important;
  font-family: var(--fontFamily) !important;
  border: none; // `1px solid ${theme.boxBorder}`,
  font-size: 14px !important;
  background: var(--background);
  text-align: center !important;
  border-bottom: none !important;
}

.link {
  display: flex;
  background: var(--color) !important;
  padding: 0.65em;
  color: black;
  justify-content: center;
  border-radius: 4px;
  font-weight: bold;
  align-items: center;
  cursor: pointer;
}

.copyLink {
  margin: 0em 1em;
  background: var(--color) !important;
  display: flex;
  border-radius: 4px;
  cursor: pointer;
}

.btnClass {
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  text-transform: none !important;
  background: var(--builderSuccess) !important;
  color: black !important;
  margin: 0em 1em !important;
  &:hover {
    background: var(--builderSuccess) !important;
  }

  &:disabled {
    background: var(--disableButton) !important;
  }
}
