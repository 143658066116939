@import 'themes/variables';

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  font-weight: bold;
}

.roomName {
  margin-bottom: 1em;
  font-family: var(--fontFamily);
  // text-align: center;
  font-size: large;
  color: black;
  text-align: left;
  padding-left: 1em;
  font-weight: 400;

  @media (max-width: 599px) {
    padding-left: 0.5em;
  }
}
