@import 'themes/variables';

.main {
  display: flex;
  height: 100%;
  padding: 1em;
  width: 100%;
  flex-direction: column;
}

.header {
}

.participantDetail {
  margin-left: 0.5em;
  font-family: var(--fontFamily);
}

.initial {
  color: #b388ff;
  display: flex;
  width: 32px;
  height: 32px;
  padding: 5px 7px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #f3ebff;
  background: #f3ebff;
  font-weight: bold;
}

.menuItem {
  display: flex;
  align-items: center;
  padding: 1em;
  border: 1px solid var(--boxBorder);
  border-radius: 4px;
  margin-top: 1em;
  background-color: var(--background);
}

.name {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.14px;
}

.email {
  color: var(--blueGray5);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: -0.12px;
}

.profile_picture {
  width: 32px;
  height: 32px;
  border-radius: 20px;
}

.logOutBtn {
  background: #FF8A80 !important;
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  color: black !important;
  text-transform: capitalize !important;
  font-size: 14px !important;

  &:hover {
    background: var(--builderSuccess) !important;
  }
}

.mainBtn {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 1em;
}

.delegateMain {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.7em;
  align-items: center;
  cursor: pointer;
}

.delegateListMain {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-bottom: 2em;
}
