@import 'themes/variables';

.button {
  background: var(--builderSuccess) !important;
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  color: black !important;
  text-transform: capitalize !important;
  margin-top: 0.5em !important;
  &:hover {
    background: var(--builderSuccess) !important;
  }
}
