@import 'themes/variables';

.main {
  display: flex;
  height: 10%;
  border-top: 1px solid var(--boxBorder);
  align-items: center;
  justify-content: center;
  padding: 1em;
  background-color: var(--background);
  z-index: 1;
}

.text {
  display: flex;
  color: var(--builderPurpleA2);
  text-decoration: none;
}

.icon {
  display: flex;
  padding: 0.5em;
  margin: 0.5em 1em;
  border-radius: 4px;
}

.activeColor {
  background-color: var(--builderPurpleA2);
}

.profile_picture {
  height: 30px;
  width: 30px;
  border: 1px;
  border-radius: 15px;
}

.activeBorder {
  border: 1px solid var(--builderPurple);
}

.activeBorderImage {
  border: 3px solid var(--builderPurple);
}
