@import 'themes/variables';

.darkOptions {
  background: var(--background) !important;
  color: var(--color) !important;
  font-family: var(--fontFamily) !important;
}

.autoComplete {
  font-family: var(--fontFamily) !important;
  font-size: 14px !important;
  color: var(--color) !important;
  background: var(--background) !important;
  margin-top: 0.5em !important;

  &:hover {
    border: 1px var(--boxBorder) !important;
  }
}
