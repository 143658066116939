@import 'themes/variables';

.dialogContent {
  padding: 20px 10px 0px !important;
}

.head {
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 500px;
  padding: 0em 1em;
}

.actionDialog {
  padding: 24px 24px !important;
  justify-content: space-between !important;
}

.cancelButton {
  font-size: 14px !important;
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  background: transparent !important;
  color: var(--color) !important;
  border: 1px solid #83889e !important;

  &:hover {
    background: transparent !important;
  }

  &:disabled {
    background: var(--disableButton) !important;
  }
}

.joinButton {
  background: var(--builderSuccess) !important;
  color: white !important;
  font-size: 14px !important;
  margin-left: 1em !important;
  font-family: var(--fontFamily) !important;
  font-weight: bold !important;
  text-transform: capitalize !important;

  &:hover {
    background: var(--builderSuccess) !important;
  }

  &:disabled {
    background: var(--blueGray) !important;
    color: white;
  }
}

.label {
  font-size: 12px !important;
  color: var(--blueGray) !important;
  font-family: var(--fontFamily) !important;
}

.textField {
  margin-top: 0.5em !important;
}

.inputField {
  font-family: var(--fontFamily) !important;
  font-size: 14px !important;
  color: var(--color) !important;
  background: var(--background) !important;

  &:hover {
    border: 1px var(--boxBorder) !important;
  }
}

.fieldMargin {
  margin-top: 1.5em !important;
}

.frequencyField {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}

.frequency {
  display: flex !important;
  justify-content: space-between !important;
  margin-top: 1.5em !important;
  margin-left: 0.7rem;
}

.date {
  display: flex;
  background: var(--background);
  padding: 0.8em 0.7em;
  border-radius: 4px;
  border: 1px solid var(--boxBorder);
  color: var(--color);
  font-family: var(--fontFamily);

  &:disabled {
    color: gray;
  }
}

.initialField {
  display: flex;
  flex-direction: column;
  width: 33%;
}

.recurringCOntainer {
  display: flex;
  width: 60%;
  flex-direction: row;
  justify-content: space-between;
}

.frequencyStyle {
  display: flex;
  width: 85%;
  flex-direction: column;
  margin-right: 1em;
}

.startDate {
  padding: 0.81em 0.7em;
  margin-right: 0.3em;
}

.endDate {
  margin-top: -0.1em;
  padding: 0.95em 0.7em;
}
