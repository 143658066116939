@import 'themes/variables';

.title {
  display: flex;
  font-size: 16px;
  margin-bottom: 0.5em;
  font-weight: bold;
}

.joinButton {
  background: var(--builderPurple);

  &:hover {
    background: var(--builderPurple);
  }
}

.blueColor {
  color: var(--blueGray);
}

.fields {
  margin-bottom: 0.5em;
}

.data {
  margin-left: 0.5em;
}

.frequency {
  text-transform: capitalize;
}

.meetingTime {
  display: flex;
  color: var(--infoBorder);
  border: 1px solid var(--infoBorder);
  width: fit-content;
  border-radius: 10px;
  padding: 0.2em 0.5em;
  font-size: 12px;
  background-color: var(--themeColor);
}

.meetingTimeMargin {
  margin-right: 0.5em;
}

.typeField {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;  
}